<template>
  <b-row v-if="items.length > 0" class="match-height">
    <b-col v-for="(candidate, i) in items" :key="`candidate-${i}`" cols="12" md="4" sm="8" class="position-relative">
      <b-card no-body class="pl-1 pt-1 candidate-wrapper position-relative" style="overflow: hidden;">
        <b-row>
          <b-col md="5" sm="8">
            <b-avatar
              :src="candidate.avatar"
              :text="avatarText(candidate.name)"
              :variant="`light-primary`"
              size="120px"
              rounded
            />
          </b-col>
          <b-col>
            <div>
              <span>Name</span>
              <h4 class="mb-50">{{ candidate.name }}</h4>

              <span>Country</span>
              <h4 class="mb-50">{{ candidate.country }}</h4>

              <span>Region/State/Province</span>
              <h4 class="mb-50">{{ candidate.region }}</h4>
            </div>
          </b-col>
        </b-row>

        <div class="mr-1 mt-1 mb-50 d-flex align-items-center justify-content-between">
          <div md="3">
            <h5 class="font-weight-normal">Gender</h5>
          </div>

          <div md="9">
            <b-badge variant="primary" class="text-capitalize text-lg">{{ candidate.gender }}</b-badge>
          </div>
        </div>

        <div class="mr-1 mb-50 d-flex align-items-center justify-content-between">
          <div md="3">
            <h5 class="font-weight-normal">Number of schools attended</h5>
          </div>

          <div md="9">
            <b-badge variant="primary" class="text-capitalize text-lg">{{ candidate.education_history }}</b-badge>
          </div>
        </div>

        <div class="mr-1 mb-50 d-flex align-items-center justify-content-between">
          <div md="3">
            <h5 class="font-weight-normal">Number of employments</h5>
          </div>

          <div md="9">
            <b-badge variant="primary" class="text-capitalize text-lg">{{ candidate.work_experience }}</b-badge>
          </div>
        </div>

        <div class="mr-1 mb-1 d-flex align-items-center justify-content-between">
          <div md="3">
            <h5 class="font-weight-normal">Age</h5>
          </div>

          <div md="9">
            <b-badge variant="primary" class="text-capitalize text-lg">{{ candidate.age }}</b-badge>
          </div>
        </div>

        <div class="" style="height: 150px;">
          <hr />
          <div>
            <h5 class="font-weight-normal">Preferred Job Interest(s)</h5>
          </div>

          <div class="mb-50">
            <span 
              v-for="(interest, index) in candidate.job_interests.slice(0, 4)" 
              :key="index" 
              class="text-capitalize text-lg d-block mb-50 text-left"
            >
              - {{ interest }}
            </span>
          </div>
        </div>

        <div class="">
          <b-link v-if="isCurrentUserJobProvider" class="text-lg text-warning d-inline-block mb-1 mt-1" :to="{ name: detailPage, params: { id: candidate._id } }">View full CV</b-link>
          <b-link v-else class="text-lg text-warning d-inline-block mb-1 mt-1" :to="{ name: 'auth-login' }">Login to view CV</b-link>
          <span class="candidate-status" :class="{ success: !candidate.employed, danger: candidate.employed}">{{ candidate.employed ? 'EMPLOYED' : 'AVAILABLE' }}</span>
        </div>
      </b-card>
    </b-col>

    <b-col cols="12">
      <div class="my-2">
        <b-pagination :value="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number last-number prev-class="prev-item" next-class="next-item" @change="$emit('update:currentPage', $event)">
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCol,
  BRow,
  BCard,
  BLink,
  BBadge,
  BMedia,
  BButton,
  BAvatar,
  BOverlay,
  BCardBody,
  BCardText,
  BCardTitle,
  BFormInput,
  BMediaBody,
  BInputGroup,
  BMediaAside,
  BPagination,
  BInputGroupAppend,
} from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BLink,
    BBadge,
    BMedia,
    BAvatar,
    BButton,
    BOverlay,
    BCardText,
    BCardBody,
    BCardTitle,
    BFormInput,
    BMediaBody,
    BPagination,
    BMediaAside,
    BInputGroup,
    BInputGroupAppend,
  },
  props: {
    currentPage: { type: Number, required: true },
    totalRecords: { type: Number, required: true },
    perPage: { type: Number, required: true },
    detailPage: { type: String, required: true },
    items: { type: Array, required: true },
  },
  methods: {
    
  }
}
</script>

<style lang="scss">
.bg-red {
  background-color: red !important;
}
.candidate-wrapper {
  position: relative;

  .text-lg {
    font-size: 13px;
  }
  .candidate-status {
    display: block;
    text-transform: uppercase;
    width: 50%;
    color: white;
    font-weight: bold;
    font-size: 14px;
    padding: 5px;
    text-align: center;
    border-radius: 5px;
    position: absolute;
    transform: rotate(-45deg);
    right: -40px;
    bottom: 30px;
    margin-left: auto;
    overflow: hidden;
  }
  .candidate-status.success {
    background-color: darkgreen;
  }
  .candidate-status.warning {
    background-color: darkorange;
  }
  .candidate-status.danger {
    background-color: darkred;
  }
}
</style>
